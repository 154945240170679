var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row position-relative",staticStyle:{"top":"0px","height":"38px","margin-bottom":"40px"}},[_c('div',{staticClass:"col-md-12 position-absolute",staticStyle:{"z-index":"9"}},[_c('ais-instant-search',{staticClass:"searchField",attrs:{"query":_vm.query,"search-client":_vm.searchClient,"indexName":"Posts","hitsPerPage":2,"minWordSizefor1Typo":6}},[_c('ais-search-box',{attrs:{"autofocus":"","show-loading-indicator":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var isSearchStalled = ref.isSearchStalled;
var refine = ref.refine;
return _c('div',{},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],staticClass:"form-control",attrs:{"id":"searchInputField","type":"search","placeholder":"input search term"},domProps:{"value":(_vm.query)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.query=$event.target.value},function($event){return refine($event.currentTarget.value)}]}}),_c('span',{attrs:{"hidden":!isSearchStalled}}),_c('loading',{attrs:{"active":isSearchStalled,"can-cancel":false,"is-full-page":false},on:{"update:active":function($event){isSearchStalled=$event}}})],1)}}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('ais-hits',{attrs:{"transform-items":_vm.transformItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return _c('div',{},[(_vm.query)?_c('b-list-group',_vm._l((_vm.filterSearchResults(items)),function(item,index){return _c('b-list-group-item',{key:'item_' + index,staticClass:"align-items-center algoliaSearchResultItem",on:{"click":function($event){$event.stopPropagation();return (function () {
                  _vm.$root.$emit('bv::hide::modal', 'searchModal');

                  _vm.$router.push({
                    name: 'EN_Post',
                    params: { postId: item.id }
                  });
                })($event)}}},[_c('h5',{staticClass:"mb-1"},[_vm._v(_vm._s(item.en_title ? item.en_title : item.title))]),_c('h6',{staticClass:"font-weight-normal mb-0 pb-0"},[_c('small',{staticClass:"text-uppercase d-inline-block pb-2"},[_vm._v(_vm._s(_vm.$globalFunctions.getCategoryById(item.categoryId).en_name))]),_c('br'),_vm._v(_vm._s(_vm.$globalFunctions.getUserById(item.createdBy).displayName)+" ")]),(item.photos && item.photos[0])?_c('div',{staticClass:"mt-2 mr-2 ml-0 bg-white text-center d-inline-block align-middle overflow-hidden float-left w-20 imgContainer",staticStyle:{"width":"103px","height":"70px"}},[_c('img',{staticStyle:{"width":"103px","height":"70px","object-fit":"cover"},attrs:{"src":item.photos[0]}})]):_vm._e(),_c('div',{staticClass:"searchResultContent"},[_c('p',[_c('small',[_vm._v(_vm._s(item.en_summary ? item.en_summary : item.summary))])])])])}),1):_vm._e()],1)}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }