<template>
  <div class="container">
    <b-row>
      <b-col lg="8">
        <article class="post-area single-blog">
          <div class="about-me">
            <h1>About the project</h1>
            <div class="my-4">
              <p>
                The project 'Writings of Memory' is being implemented in the framework of the 'Incentives for Solutions:
                Long-Lived Society', co-financed by the NGO Hub Iskra and the Ministry of Public Administration from the
                NGO Fund.
              </p>
              <img
                  :src="require('@/assets/images/sofinancerji_logoti.jpg')"
                  alt=""
                  class=""
                  style="min-width: 100%; border-radius: 20px; margin: 0px auto"
              />

              <h3>Aim of the project</h3>
              <p>The aim of the project is to use the application to strengthen the social inclusion of elderly and to
                encourage a positive image of elderly as those who preserve memory and oral traditions. In the app, you
                can upload and post stories, records and memories of elderly about various aspects of their lives in the
                past. The project wants to use this heritage, this oral tradition, as an agent to strength
                intergenerational links.</p>

              <h4>The project enables:</h4>

              <ul>
                <li>a socially useful ICT tool and promotion of digital literacy among elderly;</li>
                <li>a strengthened cooperation and the transfer of knowledge and experience between generations;</li>
                <li>a positive impact on the understanding of the social role of elderly people (the elderly as carriers
                  of memory) and the prevention of ageism;
                </li>
                <li>an improved quality of life and dignified ageing by restoring a sense of contribution and usefulness
                  to society;
                </li>
                <li>voluntary participation of elderly telling stories of the past;</li>
                <li>to raise awareness among elderly and in the general public on the value and importance of oral
                  traditions.
                </li>
              </ul>


            </div>
          </div>

          <div class="w-100 text-center" style="margin: 0px auto">
            <img
                :src="require('@/assets/images/zs_theme.jpg')"
                alt=""
                class=""
                style="min-width: 100%; border-radius: 20px; margin: 0px auto"
            />
          </div>

          <div class="my-4">

            <h3>Partners' thoughts about the project</h3>

            <img
                id="zavod-dobra-pot" name="zavod-dobra-pot"
                :src="require('@/assets/images/logo_zdp.jpg')"
                alt=""
                class="mt-2"
                style="min-width: 100%; border-radius: 20px; margin: 0px auto"
            />

            <h4 class="mt-3">Dobra pot Institute</h4>
            <blockquote style="font-style: italic">
              <p>The app 'Zapisi spomina' is dedicated to people. It was created to show the value of oral tradition and
                strengthen intergenerational links between people through reminiscing, storytelling and recording
                memories.</p>
              <p>We have very little regard of the value of each other's memories, experiences, knowledge and lessons
                learnt. Everyone's life is a story that forms the fabric of humanity, and the stories of elderly are our
                longest stories. As such, they should not be forgotten.</p>
              <p>Memories of people who lived in a different time, led a different way of life from what we know today,
                can in many ways serve as an example, a warning or as a guidance for the future. That is why they are
                worth recording, because we all want future generations to learn from the past, to stand on the
                shoulders of their ancestors and to grow even more, become even wiser.</p>
              <p>The app allows for memories and testimonies of the past to be recorded in a way that is in line with
                today's world, while at the same time being archived in a digital form. It makes it possible for anyone
                to contribute to this joint collection of memory.</p>
              <p>We truly hope that the recorded stories will open conversations, bring insights, depth, warmth,
                inspiration, meaning and thus support our common growth for a long time.
              </p>
            </blockquote>

            <img
                id="javni-park-skocjanske-jame" name="javni-park-skocjanske-jame"
                :src="require('@/assets/images/logo_psj.jpg')"
                alt=""
                class="mt-5"
                style="min-width: 100%; border-radius: 20px; margin: 0px auto"
            />

            <h4 class="mt-3">Škocjan Caves Park</h4>
            <blockquote style="font-style: italic">
              <p>Memory is at the very heart of tradition of both the individual and the community. Moreover, historical
                memory is equal to a tradition or a heritage that links an individual's or community's present with the
                past.</p>

              <p>The Škocjan Caves Park, Slovenia was established, among others, to ensure the protection of cultural
                heritage in the Škocjan Caves Regional Park area and as the manager of the UNESCO's Karst and River Reka
                Basin Biosphere Reserve it is committed, together with the inhabitants and other local stakeholders, to
                the preservation of cultural diversity. Dobra Pot Institute is one of these local actors with whom we
                are happy to cooperate in the hope to jointly create new possibilities and opportunities for the
                realization of our mission.
              </p>
            </blockquote>


            <img
                id="slovenski-etnografski-muzej" name="slovenski-etnografski-muzej"
                :src="require('@/assets/images/logo_sem.jpg')"
                alt=""
                class="mt-5"
                style="min-width: 100%; border-radius: 20px; margin: 0px auto"
            />

            <h4 class="mt-3">Slovene Ethnographic Museum</h4>
            <blockquote style="font-style: italic">
              <p>The Slovenian Ethnographic Museum (SEM) is a museum "about people, for people", a museum of cultural
                identities, a place of dialogue between the past and the present, between our own and other cultures,
                between nature and civilization. For many years, it has paid great attention to the development of
                partnerships and various forms of cooperation aiming to promote ethnological and anthropological themes
                in the field of culture and its interpretation in the contemporary world.</p>
              <p>In this context, the museum has developed a successful cooperation with the Dobra Pot Institute.
                Starting in 2019 as an active participant in the Trade Knowledge project, when one of our curators
                participated in the "Youth + Culture How To" cultural workshops in Nurmes, Finland. In 2021, SEM became
                a partner in the project 'Zapisi spomina'.</p>
              <p>SEM supports the project's aim to strengthen the social inclusion of elderly as important bearers of
                knowledge, skills and living traditions. The themes of this project are closely linked to the themes
                already included in the categorization of SEM as the Coordinator for the Preservation of Intangible
                Cultural Heritage. Therefore, the Museum will promote the project as an example of good practice in 2021
                and, once the enable its users to visit the museum at a reduced price.
              </p>
            </blockquote>
          </div>
        </article>
      </b-col>
      <b-col lg="4">
        <Sidebar></Sidebar>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
// import firebase from "firebase";
// import {db} from "../../main";

import Sidebar from "@/components/EN_Sidebar";

export default {
  name: "About",
  components: {
    Sidebar
  },
  data() {
    return {
      users: []
    };
  },
  metaInfo() {
    return {
      title: 'O projektu'
    }
  },
  beforeCreate() {
    const selectedLang = localStorage.getItem('zapisi_spomina_language');
    if(selectedLang !== null) {
      if(selectedLang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if(selectedLang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }
  },
  mounted() {
  },
  methods: {}
};
</script>
