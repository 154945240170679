<template>
  <div
      class="row position-relative"
      style="top: 0px; height: 38px; margin-bottom: 40px;"
  >
    <div class="col-md-12 position-absolute" style="z-index: 9">
      <ais-instant-search
          :query="query"
          :search-client="searchClient"
          indexName="Posts"
          :hits-per-page.camel="2"
          :min-word-sizefor-1-typo.camel="6"
          class="searchField"
      >
        <ais-search-box v-model="query" autofocus show-loading-indicator>
          <div
              slot-scope="{ currentRefinement, isSearchStalled, refine }"
              class=""
          >
            <input
                id="searchInputField"
                type="search"
                class="form-control"
                placeholder="input search term"
                v-model="query"
                @input="refine($event.currentTarget.value)"
            />
            <span :hidden="!isSearchStalled"></span>
            <loading
                :active.sync="isSearchStalled"
                :can-cancel="false"
                :is-full-page="false"
            ></loading>
          </div>
        </ais-search-box>
        <ais-hits :transform-items="transformItems">
          <div slot-scope="{ items }">
            <b-list-group v-if="query">
              <b-list-group-item
                  class="align-items-center algoliaSearchResultItem"
                  v-for="(item, index) in filterSearchResults(items)"
                  :key="'item_' + index"
                  @click.stop="
                  () => {
                    $root.$emit('bv::hide::modal', 'searchModal');

                    $router.push({
                      name: 'EN_Post',
                      params: { postId: item.id }
                    });
                  }
                "
              >
                <h5 class="mb-1">{{ item.en_title ? item.en_title : item.title }}</h5>
                <h6 class="font-weight-normal mb-0 pb-0">
                  <small class="text-uppercase d-inline-block pb-2">{{
                      $globalFunctions.getCategoryById(item.categoryId).en_name
                    }}</small
                  ><br/>{{
                    $globalFunctions.getUserById(item.createdBy).displayName
                  }}
                </h6>
                <div
                    class="mt-2 mr-2 ml-0 bg-white text-center d-inline-block align-middle overflow-hidden float-left w-20 imgContainer"
                    style="width: 103px; height: 70px;"
                    v-if="item.photos && item.photos[0]"
                >
                  <img
                      :src="item.photos[0]"
                      style="width: 103px; height: 70px; object-fit: cover"
                  />
                </div>
                <div class="searchResultContent">
                  <p>
                    <small>{{ item.en_summary ? item.en_summary : item.summary }}</small>
                  </p>
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>
        </ais-hits>
      </ais-instant-search>
    </div>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/index";
import _ from "lodash";
import Loading from "vue-loading-overlay";

export default {
  name: "Search",
  components: {
    Loading
  },
  props: {},
  data() {
    return {
      isSearchStalled: false,
      searchClient: algoliasearch(
          "ZLYHH29EQS",
          "c9cef18bef1bdc56b44b6de9687e1c1b"
      ),
      query: ""
    };
  },
  computed: {},
  mounted() {
  },
  methods: {
    transformItems(items) {
      return items;
    },

    filterSearchResults(items) {
      let confirmedPosts = [];
      _.map(items, function (val) {
        if (val.confirmed) {
          confirmedPosts.push(val);
        }
      });

      return confirmedPosts;
    }
  }
};
</script>
