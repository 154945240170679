<template>
  <div class="kotha-sidebar">
    <Search/>

    <aside class="widget about-me-widget text-center">
      <PromoSlider/>

      <div class="">
        <ul class="list-inline pb-0 mb-0">
          <li class="list-inline-item">
            <vue-goodshare-facebook
                page_url="https://zapisi-spomina.dobra-pot.si"
                has_icon
                class="text-white rounded-circle"
                style="width: 38px; height: 38px; padding-left: 8px"
            >
            </vue-goodshare-facebook>
          </li>
          <li class="list-inline-item">
            <vue-goodshare-twitter
                page_url="https://zapisi-spomina.dobra-pot.si"
                has_icon
                class="text-white rounded-circle"
                style="width: 38px; height: 38px; padding-left: 8px"
            >
            </vue-goodshare-twitter>
          </li>
        </ul>
      </div>
      <div id="paypal-button-container" style="width: 1px; height: 1px; overflow: hidden;"></div>

      <a class="btn btn-success mt-3 d-block" style="border-color: #69a637; background-color: #69a637; border-radius: 20px;" @click="donate">Support us with PayPal donation</a>

    </aside>
    <aside class="widget widget-popular-post">
      <h2 class="widget-title text-uppercase text-center">
        Categories and sub-categories
      </h2>
      <Categories/>
    </aside>
    <aside
        class="widget news-letter-widget"
        v-if="!getUser || typeof getUser() === 'undefined' || getUser() === null"
    >
      <h2 class="widget-title text-uppercase text-center">
        Subscribe to newsletter of <small class="d-block">Dobra pot Institute</small>
      </h2>

      <mailchimp-subscribe
          url="https://dobra-pot.us13.list-manage.com/subscribe/post-json"
          user-id="358f5486109d426333c963162"
          list-id="a742fc9e6d"
          @error="onError"
          @success="onSuccess"
      >
        <template v-slot="{ subscribe, setEmail, error, success, loading }">
          <form @submit.prevent="subscribe">
            <input
                type="email"
                @input="setEmail($event.target.value)"
                placeholder="vpiši email naslov"
            />
            <button
                type="submit"
                class="text-uppercase text-center btn btn-subscribe"
            >
              Subscribe
            </button>
            <div v-if="error" class="pt-2 text-danger">
              An error has occurred.<br/>
              You may already be subscribed with this email address.
            </div>
            <div v-if="success" class="pt-2 text-success">
              You have successfully submitted your newsletter subscription request!
            </div>
            <div v-if="loading" class="pt-2 text-info">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
          </form>
        </template>
      </mailchimp-subscribe>
    </aside>
    <aside class="widget latest-post-widget">
      <h2 class="widget-title text-uppercase text-center">Latest records</h2>

      <ul v-if="posts">
        <li class="media" v-for="(post, index) in posts" :key="'post_' + index">
          <div class="media-left h-100">
            <a
                @click.stop="
                () => {
                  $router.push({
                    name: 'Post',
                    params: { postId: post.id }
                  });
                }
              "
                class="popular-img h-100"
                style="width: 103px; height: 70px; margin: 0px auto;
    overflow: hidden;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 14px;
}"
            >
              <img
                  :src="$globalFunctions.getOptimizedImage(post.photos[0])"
                  style="width: 103px; height: 70px; object-fit: cover"
                  :alt="post.title"
              />
            </a>
          </div>
          <div class="latest-post-content">
            <h2 class="text-uppercase">
              <a
                  @click.stop="
                  () => {
                    $router.push({
                      name: 'Post',
                      params: { postId: post.id }
                    });
                  }
                "
              >{{ post.en_title ? post.en_title : post.title }}</a
              >
            </h2>
            <p>
              {{
                moment.unix(post.confirmedAt.seconds).format("DD. MM. YYYY")
              }}
            </p>
          </div>
        </li>
      </ul>
    </aside>
  </div>
</template>
<script>
// @ is an alias to /src
import Vue from "vue";
import MailchimpSubscribe from "vue-mailchimp-subscribe";
import {db} from "@/main";
import VueGoodshareFacebook from "vue-goodshare/src/providers/Facebook.vue";
import VueGoodshareTwitter from "vue-goodshare/src/providers/Twitter.vue";
import Search from "@/components/EN_Search";
import PromoSlider from "@/components/EN_PromoSlider";
import Categories from "@/components/EN_Categories";

export default {
  name: "Sidebar",
  components: {
    MailchimpSubscribe,
    VueGoodshareFacebook,
    VueGoodshareTwitter,
    Search,
    PromoSlider,
    Categories
  },
  data() {
    return {
      posts: [],
      slide: 0,
      sliding: null
    };
  },
  mounted() {
    const _this = this;
    let p1 = _this.$store.dispatch("setCategories");

    Promise.all([p1]).then(() => {
      _this.retrievePosts();
      _this.initPaypal();
    });
  },
  methods: {
    getUser() {
      console.log("getUser", this.$store.getters.getUser);
      return this.$store.getters.getUser;
    },

    async retrievePosts() {
      const _this = this;

      db.collection("posts")
          .where("confirmed", "==", true)
          .orderBy("confirmedAt", "desc")
          .limit(5)
          .get()
          .then(function (_docs) {
            _this.posts = _docs.docs.map(doc => doc.data());
          })
          .catch(error => {
            console.log("Error getting categories:", error);
          });
    },
    onError() {
      // handle error
    },
    onSuccess() {
      // handle success
    },

    donate() {
      document.getElementById('donate-button').click();
    },

    async initPaypal() {
      //const _this = this;

      if (document.getElementById('paypal-button-container') && document.getElementById('paypal-button-container').innerHTML) {
        document.getElementById('paypal-button-container').innerHTML = '';
      }
      Vue.prototype.$PayPal.Donation.Button({
        env: 'production',
        business: 'nava.vardjan@dobra-pot.si',
        bn: 'ZavodDobraPot_Donate_WPS_SI',
        item_name: `Podpora za aplikacijo Zapisi spomina`,
        currency_code: 'EUR',
        no_shipping: 1,
        tax: 0,
        src: 0,
        image: {
          src: 'https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif',
          title: 'PayPal',
          alt: 'Donirajte preko PayPal platforme',
        },
        onComplete: function(params) {
          console.log('paypal params', params);
          if (params.st === 'Completed') {
            this.$swal(
                "Hvala!",
                "Še naprej se bomo trudili, da upravičimo vaše zaupanje!",
                "success"
            );
          } else {
            this.$swal(
                ":(",
                "Pri donaciji je prišlo do napake. Prosimo, poskusite ponovno!",
                "error"
            );
          }
        },
      }).render('#paypal-button-container');
    },
  }
};
</script>
