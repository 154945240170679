<template>
  <div>
    <b-carousel
      id="aboutCarousel"
      v-model="slide"
      :interval="10000"
      style="height: 450px"
      indicators
    >
      <!-- Slides with custom text -->
<!--      <b-carousel-slide style="height: 440px;">-->
<!--        <div class="about-me-content">-->
<!--          <div class="about-me-img pt-0">-->
<!--            <img-->
<!--              :src="require('@/assets/images/rep_photo.png')"-->
<!--              style="width: 180px; height: 180px;"-->
<!--              class="img-me img-circle mt-0 mb-1"-->
<!--            />-->
<!--            <h2 class="text-uppercase">Projekt Zapisi spomina</h2>-->
<!--            <p>-->
<!--              krepi vključenost starejših v družbo, spodbuja prenos znanja med-->
<!--              generacijami in krepi zavedanje o pomenu ustnega izročila.<br />-->
<!--              <a-->
<!--                style="color: #6d5a3f"-->
<!--                @click="-->
<!--                  () => {-->
<!--                    $root.$emit('bv::hide::modal', 'aboutModal');-->
<!--                    $router.push({ name: 'About' });-->
<!--                  }-->
<!--                "-->
<!--                ><i class="fa fa-angle-right pr-2 mt-3"></i>Več</a-->
<!--              >-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </b-carousel-slide>-->

      <b-carousel-slide style="height: 440px;">
        <div class="about-me-content">
          <div class="pt-0">
            <div class="imageContainer">
              <img
                  :src="require('@/assets/images/promo/ehd_zs.png')"
                  style="width: 200px; height: 280px;"
                  class="mt-0 mb-1 p-2"
              />
            </div>
<!--            <h2 class="text-uppercase">Prejeli smo nagrado!</h2>-->
            <p>
              The app was awarded by the Council of Europe and the European Commission.<br/>
              <a
                  style="color: #6d5a3f"
                  href="https://www.dobra-pot.si/prejeli-smo-nagrado.html"
              target="_blank"
              >More</a
              >

            </p>
          </div>
        </div>
      </b-carousel-slide>

      <b-carousel-slide style="height: 440px;">
        <div class="about-me-content">
          <div class="about-me-img pt-0">
            <div class="imageContainer">
              <img
                :src="require('@/assets/images/promo/1.svg')"
                style="width: 180px; height: 180px;"
                class="img-me img-circle mt-0 mb-1 p-2"
              />
            </div>
            <h2 class="text-uppercase">Record memories</h2>
            <p>
              Memories of our elderly are precious. Make sure they do not pass into oblivion by
              <a
                style="color: #6d5a3f"
                @click="
                  () => {
                    $root.$emit('bv::hide::modal', 'aboutModal');
                    $router.push({ name: 'Instructions' });
                  }
                "
                >posting them in the app!</a
              >
            </p>
          </div>
        </div>
      </b-carousel-slide>

<!--      <b-carousel-slide style="height: 440px;">-->
<!--        <div class="about-me-content">-->
<!--          <div class="about-me-img pt-0">-->
<!--            <div class="imageContainer">-->
<!--              <img-->
<!--                :src="require('@/assets/images/promo/11.svg')"-->
<!--                style="width: 180px; height: 180px;"-->
<!--                class="img-me img-circle mt-0 mb-1 p-2"-->
<!--              />-->
<!--            </div>-->
<!--            <h2 class="text-uppercase">Pridobite popust</h2>-->
<!--            <p>-->
<!--              Registrirani uporabniki v letu 2021 prejmejo 50 % popusta na ogled-->
<!--              razstave v Slovenskem etnografskem muzeju. Ob vstopu pokažite-->
<!--              <a-->
<!--                style="color: #6d5a3f"-->
<!--                @click="-->
<!--                  () => {-->
<!--                    $root.$emit('bv::hide::modal', 'aboutModal');-->
<!--                    $router.push({-->
<!--                      name: 'MyProfile',-->
<!--                      params: { showCert: 'true' }-->
<!--                    });-->
<!--                  }-->
<!--                "-->
<!--                >potrdilo o registraciji.</a-->
<!--              >-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </b-carousel-slide>-->

      <b-carousel-slide style="height: 440px;">
        <div class="about-me-content">
          <div class="about-me-img pt-0">
            <div class="imageContainer">
              <img
                :src="require('@/assets/images/promo/6.svg')"
                style="width: 180px; height: 180px;"
                class="img-me img-circle mt-0 mb-1 p-2"
              />
            </div>
            <h2 class="text-uppercase">Inclusion of elderly</h2>
            <p>
              Elderly people are nowadays often lonely. Listen to their stories and record them. Or even better – teach them how to use the app.
            </p>
          </div>
        </div>
      </b-carousel-slide>

            <b-carousel-slide style="height: 440px;">
              <div class="about-me-content">
                <div class="about-me-img pt-0">
                  <div class="imageContainer">
                    <img :src="require('@/assets/images/promo/10.svg')"
                         style="width: 180px; height: 180px;"
                         class="img-me img-circle mt-0 mb-1 p-2">
                  </div>
                  <h2 class="text-uppercase">Ethnological interview</h2>
                  <p>
                    Check <a style="color: #6d5a3f" href="https://www.dobra-pot.si/assets/dobra-pot/documents/Smernice%20za%20intervju%20ZS.pdf" target="_blank">Guidelines for interviewing older people</a>, that can help you structure your conversation with memory witnesses.
                  </p>
                </div>
              </div>
            </b-carousel-slide>

<!--            <b-carousel-slide style="height: 440px;">-->
<!--              <div class="about-me-content">-->
<!--                <div class="about-me-img pt-0">-->
<!--                  <div class="imageContainer">-->
<!--                    <img :src="require('@/assets/images/promo/2.svg')"-->
<!--                         style="width: 180px; height: 180px;"-->
<!--                         class="img-me img-circle mt-0 mb-1 p-2">-->
<!--                  </div>-->
<!--                  <h2 class="text-uppercase">Učni pripomoček</h2>-->
<!--                  <p>Aplikacijo lahko uporabljate na delavnicah, v šolah ipd.-->
<!--                    <a style="color: #6d5a3f" href="https://www.dobra-pot.si/assets/dobra-pot/documents/Ucni_listi_ZS_1.pdf" target="_blank">Oglejte si nekaj načinov</a>, kako Zapise spomina uporabiti kot učni pripomoček.-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-carousel-slide>-->

<!--            <b-carousel-slide style="height: 440px;">-->
<!--              <div class="about-me-content">-->
<!--                <div class="about-me-img pt-0">-->
<!--                  <div class="imageContainer">-->
<!--                    <img :src="require('@/assets/images/promo/7.svg')"-->
<!--                         style="width: 180px; height: 180px;"-->
<!--                         class="img-me img-circle mt-0 mb-1 p-2">-->
<!--                  </div>-->
<!--                  <h2 class="text-uppercase">Bodite med prvimi</h2>-->
<!--                  <p>Prvi pričevalci in zapisovalci spominov v aplikacijo boste deležni odlične nagrade. Pohitite!-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </b-carousel-slide>-->
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "PromoSlider",
  components: {},
  props: {},
  data() {
    return {
      slide: 0,
      sliding: null
    };
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
