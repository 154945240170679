var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-list-group',_vm._l((_vm.categories),function(category,index){return _c('b-list-group-item',{key:'category_' + index,staticClass:"py-1"},[_c('span',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-' + index),expression:"'collapse-' + index"}],staticClass:"bv-no-focus-ring",staticStyle:{"font-size":"0.85rem"}},[_c('span',{staticClass:"when-closed float-left pr-1",staticStyle:{"margin-left":"-5px","margin-top":"3px"}},[_c('i',{staticClass:"fas fa-caret-right"})]),_c('span',{staticClass:"when-open float-left pr-1",staticStyle:{"margin-left":"-5px","margin-top":"2px"}},[_c('i',{staticClass:"fas fa-caret-down"})]),_vm._v(" "+_vm._s(category.en_name))]),_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-sm btn-link p-0 float-right",attrs:{"title":'Filter content by ' + category.en_name},on:{"click":function () {
            _vm.$root.$emit('bv::hide::modal', 'categoriesModal');

            _vm.$router
              .push({
                name: 'EN_HomeWithCategoryFilter',
                params: {
                  category: _vm.$globalFunctions.getCategoryById(category.id)
                    .alias
                }
              })
              .catch(function () {});
          }}},[_c('img',{attrs:{"src":require('@/assets/images/filter.svg')}})]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":'collapse-' + index}},_vm._l((_vm.subcategories),function(subcategory){return _c('div',{key:subcategory.id,staticClass:"pl-2"},[(subcategory.id.substring(0, 1) == index + 1)?_c('span',{staticClass:"d-inline-block pb-1"},[_vm._v(_vm._s(subcategory.en_name))]):_vm._e()])}),0)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }